<template>
  <div>
    <div v-if="currentStep === 1">
      <div v-if="loadingTemplates" class="text-center">
        <b-spinner></b-spinner>
      </div>
      <div v-else class="row list-templates">
        <div v-for="template in templates" :key="template.id" class="col-md-3 template-item">
          <div class="card card-pricing">
            <div class="card-body p-4">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0 mb-2 font-size-16">{{ template.name }}</h5>
                </div>
                <div class="align-self-center">
                  <feather type="user" />
                </div>
              </div>

              <img v-if="template.preview" class="mt-2 template-preview" :src="template.preview" >
              <img v-else class="mt-2 template-preview" src="@assets/images/no-image.png" >

              <!-- eslint-disable vue/no-v-html -->
              <div class="mt-4 template-description" v-html="template.description"></div>
              <!--eslint-enable-->

              <div class="text-center">
                <button
                  class="btn px-sm-4 btn-primary"
                  :class="{ 'btn-success': business.template_id === template.id }"
                  :disabled="business.template_id === template.id"
                  @click="handleSelectTemplate(template.id)"
                >
                  {{ business.template_id === template.id ? 'Selected' : 'Select' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentStep === 2">
      <ValidationObserver
        v-if="business"
        v-slot="{ handleSubmit, invalid, touched }"
        ref="businessForm"
      >
        <form
          class="authentication-form"
          @submit.prevent="handleSubmit(createBusiness)"
        >
          <b-tabs>
            <b-tab title="Business Setting" active>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.name"
                        name="name"
                        rules="required"
                        label="Company Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="business.email"
                        name="email"
                        rules="required|email"
                        label="Email"
                      />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput
                        v-model="business.phone_number"
                        name="phone"
                        rules="required|phone"
                        label="Phone Number"
                      />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Currency</label>
                        <select v-model="business.currency" class="form-control">
                          <option
                            v-for="currency in currencies"
                            :key="currency.name"
                            :value="currency.name"
                            >{{ currency.label }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Date format</label>
                        <select v-model="business.date_format" class="form-control">
                          <option
                            v-for="datetime in dateFormats"
                            :key="datetime"
                            :value="datetime"
                            >{{ datetime }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <SelectInput
                        v-model="business.timezone"
                        :options="timezones"
                        label="Timezone"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Message Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox
                            v-model="business.message_limit"
                            name="check-button"
                            switch
                            inline
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput
                            v-model="business.no_messages"
                            :readonly="!business.message_limit"
                            type="number"
                            name="no_messages"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label>Contact Limit</label>
                      <div class="mb-2 d-flex">
                        <div class="pt-2">
                          <b-form-checkbox
                            v-model="business.contact_limit"
                            name="check-button"
                            switch
                            inline
                          >
                          </b-form-checkbox>
                        </div>
                        <div class="flex-fill">
                          <TextInput
                            v-model="business.no_contacts"
                            :readonly="!business.contact_limit"
                            type="number"
                            name="no_contacts"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 mt-2">
                      <div class="mb-2 pt-2">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox
                          v-model="business.send_registration_email"
                          name="check-button"
                          switch
                          inline
                        >
                          ON - Send registration email
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div v-if="agencyLiveChatActive" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox
                            v-model="business.is_active_livechat"
                            name="check-button"
                            switch
                            inline
                          >
                            Active - Livechat
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="agencyLoopActive" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox
                            v-model="business.is_active_loop"
                            name="check-button"
                            switch
                            inline
                          >
                            Active - Loop
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div v-if="user.agency.is_enable_import_contact" class="col-md-6">
                      <div class="mb-4">
                        <div class="pt-2">
                          <span class="pr-2" style="font-weight:600;">Inactive</span>
                          <b-form-checkbox
                            v-model="business.is_enable_import_contact"
                            name="check-button"
                            switch
                            inline
                          >
                            Active - Import Contact
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="user.agency.is_template"
              title="Resources"
            >
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-4">
                      <UploadImage v-model="business.template_image" label="Template image"/>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Description</label>
                    <editor v-model="business.template_description" :api-key="tinyMCEKey" :init="{ height: 250 }" :toolbar="customToolbar" :plugins="plugins"/>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <b-form-group>
            <b-button
              v-if="!user.agency.is_template && templates.length"
              variant="primary"
              type="button"
              class="mr-2"
              @click="handlePrevStep"
            >
              Select Template
            </b-button>
            <b-button
              variant="secondary"
              :disabled="loadingCreate || (invalid && touched)"
              type="submit"
              class="float-right"
            >
              <b-spinner v-if="loadingCreate" small />
              <span v-else>Create Business</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    'editor': Editor
  },

  data() {
    return {
      business: {
        name: '',
        email: '',
        send_registration_email: true,
        date_format: 'MM/DD/YYYY',
        country: 'US',
        currency: 'Dollar',
        timezone: moment.tz.guess(),
        share: this.$route.query.share,
        template_id: false,
      },
      loadingCreate: false,
      currentStep: 1,
      templates: [],
      loadingTemplates: false,
      tinyMCEKey: null,
      plugins: 'imagetools image autolink link media lists',
      customToolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | image media',
    }
  },

  computed: {
    agencyLiveChatActive() {
      const user = this.$store.getters['auth/user'];
      if (user) {
        return user.agency.is_enabled_livechat
      }
      return false
    },
    agencyLoopActive() {
      const user = this.$store.getters['auth/user'];
      if (user) {
        return user.agency.is_enabled_loop
      }
      return false
    },
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  mounted() {
    if (!this.user.agency.is_template && !this.$route.query.share) {
      this.getTemplates()
    } else {
      this.currentStep = 2
    }
    
  },

  methods: {
    createBusiness() {
      this.loadingCreate = true

      this.$store
        .dispatch('business/create', this.business)
        .then(() => {
          this.loadingCreate = false
          this.$router.push({ name: 'agency.businesses.index' })
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.businessForm.setErrors(err.response.data.errors)
          }
          this.loadingCreate = false
        })
    },

    getTemplates()  {
      this.loadingTemplates = true
      this.$store
        .dispatch('business/templates')
        .then((templates) => {
          this.templates = templates
          this.loadingTemplates = false

          if (!this.templates.length) {
            this.handleNextStep()
          }
        })
        .catch(() => {
          this.loadingTemplates = false
        })
    },

    handleSelectTemplate(templateId) {
      this.business.template_id = templateId
      this.handleNextStep()
    },

    handleNextStep() {
      this.currentStep++
    },
    
    handlePrevStep() {
      this.business.template_id = null
      this.currentStep--
    }
  },
}
</script>

<style lang="scss">
  .template-description {
    p {
      margin-bottom: 5px;
    }
  }
</style>

<style lang="scss" scoped>
  .list-templates {
    display: flex;
    .template-item {
      margin-bottom: 20px;
      .card {
        height: 100%;
        .card-body {
          display: flex;
          flex-direction: column;
          .template-description {
            flex: 1 0 auto;
          }
        }
      }
    }
    .template-preview {
      border-radius: 5px;
      max-height: 230px;
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }
</style>